.container {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    border: 1.2px solid #EBEBEB;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9F9F9;

    color: black;
}