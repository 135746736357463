/* Customize the label element */
.toggle {
    display: inline-block;
}

/* Customize the input element */
.checkbox {
    display: none;
}

/* Customize the icon */
.icon {
    transition: .4s;
    width: 52px;
    height: 35px;
    border: 1.2px solid #EBEBEB;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon img {
    width: 22px;
    height: 22px;
}

input:checked+.icon {
    background-image: url('../../assets/icons/week.svg');
}

input:focus + .icon {
    background-color: darkgray;
  }

input:checked+.icon:before {
    transform: translateX(20px);
}