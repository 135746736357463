.days {
    display: flex;
    justify-content: space-between;
    gap: 6px;
}

.day {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.dayLabel {
    width: 33px;
    height: 33px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 auto 10px;

    border-radius: 100%;
    font-weight: 500;

    font-size: 1.15rem;
}


.overview {
    font-weight: 400;
    margin-top: 15px;
    color: #b6b6b6;
    text-align: center;
}