.container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.empty {
    text-align: center;
    margin: 5px auto;
    padding: 0 42px;
    line-height: 1.2rem;
    color: rgb(72, 72, 72);
}

.emptyTitle {
    text-align: center;
    margin: 60px auto 0;
    font-weight: 600;
    font-size: 2.6rem;
}