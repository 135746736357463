.container {
    background-color: rgb(246, 246, 246);
    width: 100%;
    height: 70px;
    border-radius: 22px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    color: white;
}