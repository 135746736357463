.container {
    border: 1px solid #E7E7E7;
    filter: drop-shadow(0px -3px 70px rgba(0, 0, 0, 0.25));
    border-radius: 30px;
    overflow: hidden;
    width: 100%;
    color: black;
    text-decoration: none;
    background-color: white;
}

.container:focus {
    background-color: rgba(187, 187, 187, 0.08);
    filter: none;
}

.thumbnail {
    width: 100%;
    max-height: 20vh;
    object-fit: cover;
}


.text {
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.tagBox {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.description {
    line-height: 1.1;
    letter-spacing: 0.227528px;
    padding: 0 5%;
    color: #979797;
}