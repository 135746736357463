.container {
    min-height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
}


.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 18%;
    text-align: center;
    margin: 0 auto;
}

.closeBtn {
    padding: 0;
    border-radius: 100%;
    min-width: 30px;
    height: 30px;
    border: 1.2px solid #EBEBEB;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9F9F9;
    color: black;

    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;
}

.closeBtn > * {
    font-size: 1.1rem;
}


.platformToggle {
    display: flex;
    margin: 40px 0;
}

.platformToggle button {
    /* flex: 1; */
    padding: 8px;
    border: none;
    font-size: 15px;
    font-weight: 600;
    color: #676767;
    cursor: pointer;
    width: 100px;
    /* set the width of the buttons */
    border-radius: 50px;
    background: none;
}


.platformToggle button.selected {
    background-color: black;
    /* change the background color of the selected button */
    color: #fff;
}

.icon {
    width: 40px;
    height: 40px;
}

.installTitle {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 20px 0;
}