.votingForm {
    display: flex;
    gap: 5px;
    margin-top: 15px;
}


.votingForm label {
    display: inline-block;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #F5F5F5; 
    border-radius: 10px;
    height: 75px;
    border: 1px solid #EEEEEE;
    font-size: 1.4rem;
    font-weight: 500;
    color: #DBDBDB;
}


.btn {
    background-color: #F4F4F4;
    border: none;
    min-height: 50px;
    border-radius: 10px;
    color: #6260E6;
    font-weight: 600;
    font-size: 1.1rem;
    width: 100%;
    margin-top: 10px;
}

.check {
    display: none;
}

.votingForm input[type="radio"]:checked+label {
    background-color: #6260E6;
}

input[type="radio"]+label {
    content: "";
}

input[type="radio"]:checked+label {
    background-color: blue;
    color: white;
}