.container {
    min-height: 90vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.title {
    text-align: center;
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 10px;
}

.sub {
    text-align: center;
    font-size: .9rem;
    color: rgb(77, 77, 77);
}

.wrapper {
    margin-bottom: 30px;
}

.animation {
    height: 40vh;
}