.months {
  margin: 0;
}

.month {
  padding: 40px 0;
  border-bottom: 1px solid #DDDDDD;
}

.month:first-of-type{
  padding-top: 0;
}

.month > table  {
  min-width: 100% !important;
  margin: 0 auto;
}

.month > table > tbody > tr > td {
  padding: 1%;
}

.month > table > tbody > tr > td > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  
}

.day {
  display: table-cell;
  min-width: 100% !important;
  height: 45px;
  vertical-align: middle;
  text-align: center;
  border-radius: 10px;
  background-color: rgb(246, 246, 246);
  /* margin: 5px; */
  /* background-color: indianred; */
}


.today {
  background-color: #007bff !important;
  color: #fff;
}


.caption {
  color: rebeccapurple;
  z-index: 0;
  margin-bottom: 20px;
  font-size: 1.1rem;
  font-weight: bold;
  color: black;
}