.lottie {
    max-width: 500px;
    margin: 15% auto 0;
    height: 35vh;
}

.tip {
    text-align: center;
    color: rgb(114, 114, 114);
    margin-top: 20px;
    padding: 0 10%;
}

.block {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 15px;

    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 84px rgba(187, 187, 187, 0.05);

    text-decoration: none;
    color: black;
}

.icon {
    height: 50px;
}

.chevron {
    margin-left: auto;
}

.tag {
    font-weight: 500;
    margin-bottom: 5px;
}

.sub {
    font-size: .9rem;
    color: #5c5c5c;
}

.top, .bottom {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.bottom {
    border-top: 1px solid #E7E7E7;
    padding-top: 30px;
}

.top {
    padding-bottom: 30px;
}


.blocks {
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 6%;
}

.input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 6px;
    height: 50px !important;
    text-align: center;
    font-size: 1.1rem;
    margin: 10PX 0 15px 0 ;
    box-sizing: border-box;

}