.title {
    font-size: 2.8rem;
    font-weight: 500;
    /* line-height: 10px; */
    font-family: 'SF Pro';
    margin-bottom: 20px;

}

.devider {
    border: none;
    /* remove the default border */
    height: 1px;
    /* set the height */
    background-color: #DDDDDD;
    /* set the color */
    margin: 15px 0;
    /* add some margin */
}

.text {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.4rem;
}

.subtitle {
    font-size: 1.4rem;
    font-weight: 500;
}

.introductory {
    color: #392A97;
    font-weight: 500;
    font-size: 1.3rem;
}

strong {
    font-weight: 800;
}

br {
    line-height: 1.5px;
}