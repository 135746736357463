.title {
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.description {
  font-size: 1.02rem;
  font-weight: 300;
  line-height: 1.15rem;
  max-width: 280px;
  margin-bottom: 60px;
}

.descriptionSmall {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.1rem;
  max-width: 280px;
  margin-bottom: 10%;
}

.input {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  border-radius: 6px;
  height: 50px !important;
  text-align: center;
  font-size: 1.1rem;
  margin: 15px 0 0;
  box-sizing: border-box;
}

.link {
  font-weight: 500;
  color: #6260E6;
}

.timeInput {
  min-width: calc(100%);
  height: 50px !important;
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  border-radius: 6px;
  text-align: center;
  font-size: 1.1rem;
  margin: 15px 0 0;
  box-sizing: border-box;
}



.nfccontainer {
  background-repeat: no-repeat;
  background-position: center top 150px;
  background-size: contain;
  min-height: 60vh;
  /* Add this line */
}


.img {
  margin: 0 auto;
  display: block;
}


.label {
  margin-bottom: 10px;
  line-height: 1.2rem;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
  line-height: 1.2rem;
}


.labelSmall {
  margin-bottom: 10px;
  font-size: .9rem;
}

.checkboxBox {
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  border-radius: 1px;
  margin: 0;
  width: 25px;
  height: 25px;
}

.checkboxBox:checked {
  background-color: #6260E6 !important;
  border-color: #6260E6 !important;
}


.fullWidth {
  max-width: 100%;
}

.termsBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.2rem;
  border: 1px solid #EDEDED;
  border-radius: 10px;
  padding: 20px;

}

.box {
  max-width: 80%;
  font-size: .95rem;
  color: #666666;
  
}

.agree {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
  margin-bottom: 10px;
}
.timePicker {
  position: relative;
}

.timePicker input {
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #ccc;
  background-color: transparent;
  cursor: pointer;
}

.timePicker input:focus {
  outline: none;
}

.timePicker .options {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.timePicker .option {
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
}

.timePicker .option:hover {
  background-color: #f9f9f9;
}
