.nextButton {
    background-color: #6260E6;
    border: none;
    min-height: 50px;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
    width: 100%;
}

.inactive {
    background-color: #DADADA;
    border: none;
    min-height: 50px;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
    width: 100%;
    pointer-events: none;
}

.backButton {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    border: 1.2px solid #EBEBEB;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9F9F9;
    margin-bottom: 40px;
    color: black;
}

.laterButton {
    background: none;
    border: none;
    min-height: 50px;
    border-radius: 10px;
    color: #8B8B90;
    font-size: 1rem;
    width: 100%;
}