.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.smileContainer {
    background-color: white;
    box-shadow: 0px 0px 4.56977px rgba(0, 0, 0, 0.06);
    display: inline-block;
    border-radius: 100%;
    width: 65px;
    height: 65px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.smile {
    width: 40px;
    height: 40px;
}

.progressQuote {
    text-align: center;
}