.title {
    font-size: 3rem;
    font-weight: 500;
    /* line-height: 10px; */
    font-family: 'SF Pro';

}

.streak {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: flex-end;
}

.installContainer {
    display: none;
}

.installContainer.visible {
    display: block;
}

.toggle {
    padding-bottom: 5px;
}


.container {
    padding-bottom: 40px;
}

.overview {
    font-weight: 500;
    margin-bottom: 20px;
    color: #303030;
}