.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid #EDEDED;
    padding: 10px;
    border-radius: 10px;
}

.streakContainter {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    margin-bottom: 5px;
}

.animation {
    max-width: 40px;
    margin-bottom: 2px;
}

.days {
    color: #FF7E0E;
    font-weight: 500;
    font-size: 1.1rem;
    padding-bottom: 2px;
}

.description {
    font-weight: 500;
    font-size: 1rem;
}

.newWeekMessage {
    font-weight: 500;
    font-size: 1.05rem;
    text-align: center;
    padding: 5px;
    border-radius: 10px;
    background-color: #fff;

    margin-top: 10px;
  }
  