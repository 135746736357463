.container {
    border: 1px solid #E7E7E7;
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    color: black;
    text-decoration: none;
    filter: drop-shadow(0px 4px 5px rgba(181, 181, 181, 0.25));
    background-color: white;
}
.container:focus {
    background-color: rgba(187, 187, 187, 0.08);
    filter: none;
}

.thumbnail {
    width: 100%;
}

.text {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tagBox {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.title{
    font-size: 1.4rem;
    font-weight: 600;
}

.description {
    font-size: .9rem;
    color: gray;
}