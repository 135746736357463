.container {
    display: flex;
    align-items: center;
    gap: 15px;
    border: 1px solid #EDEDED;
    padding: 15px;
    border-radius: 10px;
}

.detailContainer {
    display: flex;
    flex-direction: column;
}

.checkbox {
    background-color: #F4F4F4;
    padding: 10px 15px;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: auto;
}

.check {
    width: 25px;
    height: 25px;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.name {
    font-weight: 600;
    font-size: 1.1rem;
    padding-bottom: 5px;
}

.img {
    border-radius: 15px;
}

.time {
    font-weight: 500;
    font-size: 1.1rem;
}