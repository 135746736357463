.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 10%;
    gap: 20px;
}


.img {
    margin: 0 auto;
    min-width: 90px;
}

.quote {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: .5px;
    line-height: 1.1rem;
}