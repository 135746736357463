.lottie {
    max-width: 500px;
    margin: 5vh auto 0;
    height: 30vh;
}

.tip {
    text-align: center;
    color: rgb(54, 54, 54);
    margin-top: 20px;
    padding: 0 5%;
    font-size: .9rem;
}