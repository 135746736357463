.container {
    height: 30px;
    border: 1.2px solid #EBEBEB;
    padding: 0 15px;
    border-radius: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.selected {
    background-color: #6260E6;
    color: white;
}

.clear {
    max-width: 15px;
    margin-left: 8px;
    color: rgba(255, 255, 255, 0.685);
}