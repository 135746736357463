.container {
  padding:
    env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px);
    min-height: 100vh;
}

.slide {
  height: 100%;
  /* min-height: calc(100vh - 60px); */
  min-height: 100vh;
  padding: 0 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.swiper-wrapper {
  height: 100%;
}

.swiper-slide {
  height: 100%;
  min-height: calc(100vh - 60px);
}

.buttonContainer {
  position: relative;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  min-width: calc(100% - 30px);
}

.scrolledBtns {
  position: sticky;
  margin: auto 0 0;
  padding-bottom: 40px;
}

.introSlide {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

.introImg {
  margin: 0 auto;
  display: block;
  width: 90%;
  max-width: 450px;
  height: 35vh;
}

.introTitle {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 10%;
}

.introDescription {
  font-weight: 1.2rem;
  text-align: center;
  padding: 5% 6%;
  color: #767575;
  font-size: 1rem;
  line-height: 1.25rem;
}

.dotContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 15%;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 0.5rem;
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #6260E6;
}


.introWarning {
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  text-align: center;

  color: #6260E6;
  padding: 20px 8% 0;

}