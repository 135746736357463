.container {
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: -30px;
    background-attachment: fixed;

}

.textBlock {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border: 2px solid #FCFCFF;
    box-shadow: 0px -5px 24px rgba(0, 0, 0, 0.5);
    margin-top: 150px;
    padding: 35px 0;
}

.title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.tagBox {
    display: flex;
    gap: 10px;
    margin: 15px 0 40px;
    flex-wrap: wrap;
}

.description {
    font-size: 1.05rem;
    font-weight: 300;
    line-height: 1.4rem;
}